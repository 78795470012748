@media (min-height: 481px) and (max-height: 526px) and (min-width: 520px) and (max-width: 530px) and (orientation: portrait) {
    body {
        background-color: #1a1b18 !important;
    }

    /*524px 525px*/
    .categoriespuzzles {
        width: 10vw !important;
        height: 10vh !important;
        margin-top: 20vw !important;
        margin-left: 70vw !important;
        padding: 0% !important;

        writing-mode: vertical-rl !important;
        /* Text flows vertically from right to left */
        text-align: center !important;
        /* May not be necessary with the transform, but can help with spacing */
        display: flex !important;
        justify-content: center !important;
        /* Centers content vertically */
        align-items: center !important;
        /* Centers content horizontally, given the vertical text direction */
        // transform: rotate(180deg);
        /* Flips the text so it reads from top to bottom */
        line-height: 1 !important;
        /* Adjusts the spacing between letters */


        position: fixed !important;
    }

    .custom-large-button {
        padding-left: 1.5vw !important;
        margin-left: 11vw !important;

    }

    .controlbottons {
        margin-left: -10vw !important;
        position: fixed !important;

    }

    .control-btn {
        width: 10vw !important;
        height: 20vh !important;
        padding: 0% !important;
        writing-mode: vertical-rl !important;
        /* Text flows vertically from right to left */
        text-align: center !important;
        /* May not be necessary with the transform, but can help with spacing */
        display: flex !important;
        justify-content: center !important;
        /* Centers content vertically */
        align-items: center !important;
        /* Centers content horizontally, given the vertical text direction */
        transform: rotate(180deg) !important;
        /* Flips the text so it reads from top to bottom */
        line-height: 1 !important;
        /* Adjusts the spacing between letters */
    }

    .turnplay {
        margin-left: 75% !important;
        max-width: 1vw !important;
        margin-top: 40%;
        position: fixed !important;
        font-size: xx-large !important;
        height: 40vh !important;
    }





    .turnis {
        font-size: 16px !important;
        /* Use a fixed pixel value or em for scalability */
        margin-top: -60% !important;
        /* Remove additional spacing */
        margin-bottom: 0 !important;
        /* Remove additional spacing */
        height: auto !important;
        /* Allow the height to adjust based on content */
    }

    .turnplay .Black,
    .turnplay .White {
        padding: 8px !important;

        font-size: medium !important;
        color: white !important;

        background-size: contain !important;

        background-repeat: no-repeat !important;
        background-position: left !important;

        z-index: 1 !important;
        /* Standardize stacking order */
        display: inline-block !important;
        /* Allow buttons to sit in line with text or other inline elements */
        text-align: center !important;
        /* Center the text inside the button */
        width: 10% !important;
        /* Allow the width to adjust based on content, or set a specific width */
        height: 40% !important;
        /* Allow the height to adjust based on content, or set a specific height */
        position: fixed !important;
        margin-left: -46vw !important;
        margin-top: 10vw !important;

    }

    .cg-wrap {
        z-index: 0 !important;
        margin-top: 27vh !important;
        margin-left: 11vw !important;
        $foldopened: 70vw;
        width: $foldopened !important;
        height: $foldopened !important;
    }

    .hints .cg-wrap {
        $foldopenedhint: 60vw;
        //  $foldopenedhint: 0vw;
        width: $foldopenedhint !important;
        height: $foldopenedhint !important;
        margin-left: -8vw !important;
    }

    // .categorieshint {
    //     display: block !important;
    //     margin-top: 24vw !important;
    //     margin-left: -20vw;

    // }

    // .addedOrRestRating {
    //     position: fixed !important;
    //     margin-top: -60vw !important;
    // }

    .scrollable-list {
        height: 10vw !important;

    }

    .infohood {
        margin-top: 3vw !important;
    }

    .happy {


        width: 13vw !important;
        margin-left: 30% !important;
        margin-top: -1% !important;
        padding: 3% !important;
    }

    .backwordbtn {
        margin-left: -50vw !important;
        /* Adjusted for the button to start from the left edge */
        position: fixed !important;
        /* Fixed position to stay visible while scrolling */

        /* Center the button vertically */
        transform: translateY(-50%);
        /* Adjust position to truly center based on its height */
        height: 60px;
        /* Example height, larger than width */
        width: 40px;
        /* Example width, ensure height is larger */
        background-color: #1a55c1;
        /* Light grey background, adjust as needed */
        border: none;
        /* Remove border */
        cursor: pointer;
        /* Change cursor to pointer to indicate clickable */
        font-size: 30px;
        /* Adjust based on the visual preference for the arrow */
        text-align: center;
        /* Center the arrow or text inside the button */
        /* Additional styling for better appearance */
        border-radius: 5px;
        /* Slightly rounded corners */
        box-shadow: 0 4px 8px rgba(242, 194, 3, 0.98);
        /* Subtle shadow for depth */
        outline: none;
        /* Remove outline to keep the focus style clean */
        top: 50% !important;
    }

    .backwordbtn:hover {
        background-color: #e0e0e0;
        /* Slightly darker on hover for feedback */
    }

    .forwardbtn {
        margin-right: 0vw !important;
        /* Adjusted for the button to start from the right edge */
        position: fixed !important;
        /* Fixed position to stay visible while scrolling */
        right: 0;
        /* Align to the right edge of the viewport */
        top: 50% !important;
        /* Center the button vertically */
        transform: translateY(-50%);
        /* Adjust position to truly center based on its height */
        height: 60px;
        /* Example height, larger than width */
        width: 40px;
        /* Example width, ensure height is larger */
        background-color: #1a55c1;
        /* Background color, adjust as needed */
        border: none;
        /* Remove border */
        cursor: pointer;
        /* Change cursor to pointer to indicate clickable */
        font-size: 30px;
        /* Adjust based on the visual preference for the arrow */
        text-align: center;
        /* Center the arrow or text inside the button */
        border-radius: 5px;
        /* Slightly rounded corners */
        box-shadow: 0 4px 8px rgba(242, 194, 3, 0.98);
        /* Subtle shadow for depth */
        outline: none;
        /* Remove outline to keep the focus style clean */
    }


    .btn-close {

        margin-top: 7vw !important;
    }
}

// @media(screen-spanning: single-fold-vertical) {
//     body {
//         background-color: rgb(0, 255, 0) !important;
//     }
// }

// @media(screen-spanning: single-fold-horizontal) {
//     body {
//         background-color: rgb(255, 0, 0) !important;
//     }
// }
@media only screen and (min-width: 2208px) and (max-height: 1768px) {}