@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 4/3) and (max-height: 380px) {
  /* Styles for the specified conditions */
  /* Add your styles here */

  body {
    background-color: #1a1b18 !important;
    font-size: x-small !important;
  }

  .col {
    padding: 5%;
  }

  .column2 {
    padding-top: 0.8%;
  }

  .cg-wrap {

    $boarwidth: 40vw;
    width: $boarwidth !important;
    height: $boarwidth !important;
    font-size: x-small !important;
    margin-left: 7%;
  }

  .infoPuzzle {
    margin-left: -3%;
    max-width: 100% !important;

    font-size: xx-small !important;
    color: white !important;
  }

  .ratings {

    font-size: x-small !important;
    margin-bottom: -10px !important;
  }

  .navbarhood {
    margin-left: -20px !important;
    margin-top: -5%;
    position: fixed !important;
    z-index: 1000 !important;
    width: 20% !important;
    font-size: x-small !important;

  }

  .cg-wrap coords.ranks {
    top: 0px !important;
    left: -5% !important;
    color: red !important;
  }

  .cg-wrap coords.files {

    top: 90% !important;
    left: 0% !important;
    color: red !important;
  }

  .cg-sq-coord {
    /* Replace with the actual class name */
    background-color: red !important;
  }

  .cg-wrap coords.ranks {
    top: 0px !important;
    left: -5% !important;
    color: red !important;
  }

  .cg-wrap coords.files {

    top: 90% !important;
    left: 0% !important;
    color: red !important;
  }

  .cg-sq-coord {
    /* Replace with the actual class name */
    background-color: red !important;
  }
}