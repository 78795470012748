#root {
    max-width: 1800px !important;
}

@media (min-height: 481px)
/* Adjust the max-height as needed */
and (orientation: portrait) and (min-aspect-ratio: 9/21)
/* Adjust based on the specific aspect ratio you want to target */
    {
    /* Styles go here */
    /* CSS */




    // // Bootstrap Breakpoints for max-width
    $breakpoints: (
        xs: 1000px,

        // Upper limit for xs
        sm: 1000.98px,
        // Upper limit for sm

    );


$infor-height: 19vw;
$logo-width: 5vw;
$cardwidth: 20vw;
$image-category: 3vw;
$card-img: 10vw;
$piecat: 17vw;
$categories-width: 27rem;
$menu-max-height: 36vw;
$cardhood-width: 27vw;






$cardhood-max-width-xs: 100%;
$navbarhood-margintop-sm-portrait: 0px;

$logo-margin-left-sm-portrait: 0em;
$logo-width-sm-portrait: 8vw;
$logo-text-margin-left-sm-portrait: 12vw;
$infor-height-xs: 25vh;
$ratings-cards-margin-top: -330vw;
$ratings-cards-margin-bottom: 50vh;
$cardbottons-margin-top-xs: 100vw;
//360x640 Galaxy S5

//Board portrait samsung fold opened
$cg-wrap-margin-left-sm-portrait: 0vw;
$cg-wrap-margin-top-sm-portrait: 21vw;
$withboardxs: 77vw; // 575.98px




$withboardsm: 70vw; //   767.98px

$withboard: 20vw; // 575.98px





//Colors bacgrounds
$body-bg: #1a1b18;
$category-item1-bg: $body-bg;
$card_bg: $body-bg;
$card-title-bg: #5a5a5ab5;

$scrollbar-ripe-malinka: #457737;
$scrollbar-ripe-malinka: linear-gradient(120deg, #030003 0%, #330a10 100%);

//borders
$card-title-border-bg: #f1bf08;

$border: none;
//Text color
$body-text-color: whitesmoke;
$Black-play-color: #000000;
$h2: #e76434;
$h3: #000000;
//measures
$puzzlecategoriesxs: 22vw;
@media screen and (min-height: 524px) and (max-width: map-get($breakpoints, 'sm')) {
    body {

        background-image: url("../../public/a3.webp") !important;

    }

    .logo {
        margin-left: 5% !important;
    }

    html,
    body {
        max-width: 100%;
        overflow-x: hidden;
    }



    .infor {

        max-height: $infor-height;
        overflow-y: auto;
        /* Enables vertical scrolling if content exceeds 100px */
    }

    .spare-pieces {
        display: inline-flex;
        max-width: 47vw;

    }


    .piece {


        background-repeat: no-repeat;
        background-size: contain;
        /* or cover */
    }

    //finish Landscape
    .scrollbar-ripe-malinka::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
        background-color: $scrollbar-ripe-malinka;

        border-radius: 10px;
    }

    .scrollbar-ripe-malinka::-webkit-scrollbar {
        width: 12px;
        background-color: $body-bg;
        border-color: green;
    }

    .scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
        background-image: $scrollbar-ripe-malinka;

    }

    .example-1 {
        position: relative;
        overflow-y: scroll;
        height: $infor-height;

        width: $categories-width;
        border: $border;
    }

    .ratings {
        font-size: 0.9vw !important;
        text-align: center;

    }

    .card {
        border-color: $card-title-border-bg;
        border-radius: 1.5rem;
        color: $body-text-color;
        background-color: $card_bg;
        // width: $cardwidth;

    }

    .card-title {

        background-color: $card-title-bg;
        color: $body-text-color;

        border-color: $card-title-border-bg;
        border-radius: 1.5rem;
    }

    .category-item1 {

        background-color: $category-item1-bg;
    }

    .medal {
        width: 4vw;
    }

    .puzzle-rating {
        width: 3vw;

    }

    .puzzle-categories {
        text-align: center;
    }

    .logo {
        // margin-left: 2em;
        // margin-bottom: 1em;
        width: $logo-width;
        height: 100%;
    }

    .logo-text {
        font-weight: 500;
        font-size: xx-large;
        color: #e76434;
    }

    .image-category {

        width: $image-category;
        height: 100%;
    }

    .card-img {

        width: $card-img;
        height: 100%;
    }

    .piecat {

        width: $piecat;
        height: 100%;
        padding-left: 2em;

    }



    .card {
        text-align: center;
    }

    .title-cat-img {
        text-align: center;
    }

    .text-center {
        text-align: center;
    }

    h2 {
        color: $h2;
        font-size: 1.2rem !important;
    }



    h3 {
        color: $h3
    }


    .Black {
        background-color: $body-bg;
        color: $Black-play-color;
    }

    .White {
        background-color: $body-bg;
        color: $body-text-color;
    }

    .customCanvasBackgroundColor {
        background-color: $body-bg;
    }

    .chessboard {
        border-color: red;
        width: $withboard;
        height: $withboard;
    }

    .menu-after-logo {
        max-height: $menu-max-height;
        overflow-y: auto;


    }



    //scrool menu
    .menu-after-logo::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
        background-color: $scrollbar-ripe-malinka;

        border-radius: 10px;
    }

    .menu-after-logo::-webkit-scrollbar {
        width: 12px;
        background-color: $body-bg;
        border-color: green;
    }

    .menu-after-logo::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
        background-image: $scrollbar-ripe-malinka;

    }





    .navbar {
        color: #d2e108;

    }

    .cardhood {

        background-color: $body-bg;
        max-width: $cardhood-width;
    }







    .logo-text {

        margin-left: $logo-text-margin-left-sm-portrait;
    }

    .logo {

        width: $logo-width-sm-portrait;
        margin-left: $logo-margin-left-sm-portrait;
    }

    .navbarhood {

        margin-top: $navbarhood-margintop-sm-portrait !important;
    }



    .puzzlecategories {

        margin-top: $puzzlecategoriesxs !important;
    }





    .medal {
        width: 14vw;
    }


    .ratings {

        // font-size: 4vw !important;
        // text-align: center;
        // font-weight: 700;
    }

    .ratings-cards {

        margin-top: $ratings-cards-margin-top !important;
        margin-bottom: $ratings-cards-margin-bottom !important;
    }

    .puzzle-rating {
        width: 10vw;
    }

    .cardhood {

        background-color: $body-bg;
        max-width: 100% !important;

        margin-left: 0 !important;

    }

    .cg-wrap {
        margin-top: $cg-wrap-margin-top-sm-portrait !important;
        margin-left: $cg-wrap-margin-left-sm-portrait !important;
        width: $withboardxs !important;
        height: $withboardxs !important;
        position: fixed !important;

    }

    .example-1 {

        height: $infor-height-xs;
        width: 27rem;

    }





    .cardbottons {

        z-index: 0 !important;
        margin-top: $cardbottons-margin-top-xs !important;
        position: sticky;

    }

    .accordion-body {
        z-index: 1 !important;
    }

}




/*styles for landscape*/
/*styles for landscape*/
/*styles for landscape*/


/* Accordion and Card Styles */
.cardhood.puzzlescategory {
    width: 100%;
    /* Adjust the width as needed */
}

.accordion-item {
    margin-bottom: 10px;
    /* Spacing between accordion items */
}

.accordion-button {
    text-align: left;
    /* Aligns the text to the left */
    white-space: nowrap;
    /* Keeps the text in a single line */
}

.accordion-body {
    max-height: 50vh;
    /* Set a max height for the accordion body */
    overflow-y: auto;
    /* Add a scrollbar if content exceeds max height */
}

/* Styles for the content inside the accordion */
.category-item1 {
    width: 100%;
    /* Ensure the inner cards take full width */
}

.card-title-bg-color {
    background-color: #f8f9fa;
    /* Adjust background color as needed */
}






.afterpgneditor {
    max-width: 20vw !important;
    max-height: 20vw !important;
}


.hintbuttons {
    margin-top: -490px !important;
}





.login-google-alert {
    width: 22vw !important;
    z-index: 1;
}

.coach-ai {
    display: block;
    /* Ensure it's a block-level element */
    margin: 0 auto;
    /* Center the image horizontally */
    max-width: 60%;
    /* Ensure the image doesn't exceed its container */
}

.modal-content {
    display: block !important;
    margin: 0 auto !important;
    max-width: 100%;
    background-color: $body-bg;
    color: $body-text-color;
}

.modal-title {
    margin: 0 auto !important;
}





ul li {
    text-align: left;
}

button {
    font-size: 12px;
    /* Adjust as needed */
}

.buttonscat {
    margin: 0.2em;
    width: 100% !important;
    font-size: large !important;
}



.column1 .btn {
    width: 100%;
    padding: 1vw;
    margin: 0.5vw;
}

.iconess {
    width: 5vw;
    height: 5vw;
}

.col {

    background-color: #1a1b18;
    border: 2px solid #e76434;
    margin: 3%;
    padding: 0.1rem;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    /* Hover effect to slightly lighten the background */
    &:hover {
        background-color: #1a1b18;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Increase shadow on hover */

    }
}

.control-btn {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

    width: 30%;
    padding: 1vw;
    margin: 0.4vw;
}

.line2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    width: 100%;
    color: whitesmoke;
}


.hint {
    display: block !important;
}



.alert {
    width: 90vw !important;
    height: 90vh;
    max-width: 90vw;
    border: 3px solid #757e72;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    position: fixed;
    text-align: center !important;
}

.alert-success {
    width: 90vw !important;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 3px solid #5ca145;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}

.categorieshint {
    margin-top: 30vw !important;
    margin-left: -65vw !important;
}

.col .alert {
    border: 3px solid #dff306 !important;
}

.col .alert-success {
    border: 3px solid #5ca145 !important;
}


.alert-error {
    width: 90vw !important;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 2px solid #e76434;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}

.navbarhood {
    margin-left: -20px !important;

    position: fixed !important;
    z-index: 1000 !important;
    width: 20% !important;
    font-size: x-small !important;

}

/* UserCategories.css */

.scrollable-list {
    margin-top: -20vw !important;
    margin-left: 50% !important;
    max-height: 20vw;
    overflow-y: auto;
    border: 1px solid #4c5249;
    /* Optional: adds a border around the list */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds a subtle shadow */
    list-style-type: none;
    /* Removes default list bullet points */
    padding: 0;
    /* Resets default padding */
    margin: 0;
    /* Resets default margin */

}

.scrollable-list li {
    padding: 10px;
    /* Adds some padding inside each list item for better spacing */
    color: #333;
    /* Dark text color for better readability */
}

.scrollable-list li:nth-child(odd) {
    background-color: #fff;
    /* White background for odd items */
}

.scrollable-list li:nth-child(even) {
    background-color: #f2f2f2;
    /* Light gray background for even items */
}

@keyframes hurrahAnimation {

    0%,
    100% {
        transform: scale(1);
        /* Original size */
    }

    50% {
        transform: scale(1.2);
        /* Scale up to 120% */
    }
}

.happy {
    display: block;
    margin: 0 auto;
    width: 17% !important;
    text-align: center;
    color: $body-text-color;
    /* Make sure this variable is defined or replace it with a specific color */
    animation: hurrahAnimation 0.5s ease-in-out infinite;
}


.alert-heading {
    font-size: large;
    color: white;
    background-color: #1a1b18;
    border: 2px solid #e76434;

    padding: 0.9rem;
    text-align: center;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.alert-success .alert-heading {
    background-color: #1a1b18;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 3px solid;
    font-size: large;
}

.logoportrait {
    text-align: left !important;

    margin-top: 0vw !important;
    margin-left: 0vw !important;
    width: 70vw !important;
    // padding: 10%
}

;

.navbar-nav {
    width: 90vw !important;
    background-color: #1a1b18;
    padding: 10%;
    padding-top: 3%;
}

.infoPuzzle {
    width: 97% !important;
    position: fixed;
}

.ratingscol {
    width: 100% !important;
    margin-top: -7vw !important;
}

.h2 {
    color: #e76434 !important;
    font-size: medium;
}







.alertcategoriespuzzle {
    width: 50vw !important;

    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434 !important;

    position: fixed;
    margin-left: 0vw !important;
    margin-top: 0vw !important;


}

.play-active {
    position: fixed;
    color: whitesmoke;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* This prevents scrolling */
}

.turnplay .Black {
    padding: 2% !important;
    background-color: #1a1b18;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzcuMTciIGhlaWdodD0iMTc3LjE3IiBzaGFwZS1yZW5kZXJpbmc9Imdlb21ldHJpY1ByZWNpc2lvbiIgaW1hZ2UtcmVuZGVyaW5nPSJvcHRpbWl6ZVF1YWxpdHkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiB2aWV3Qm94PSIwIDAgNTAgNTAiPjxwYXRoIGQ9Im0yNSA0Ni40NWgtMTMuMzk0Yy0uNjYtMS42NTEtLjk5MS0zLjM3OC0uOTkxLTUuMTgyIDAtMy4wNTYuODY0LTUuOCAyLjU5OS04LjIzOCAxLjczNi0yLjQzIDMuOTcxLTQuMTY2IDYuNjk3LTUuMjA3LTEuMTY4LS41NDItMi4xMjUtMS4zNjMtMi44NjItMi40NjQtLjczNy0xLjEwMS0xLjEwMS0yLjM0NS0xLjEwMS0zLjczNCAwLTEuNzM2LjU3Ni0zLjI0MyAxLjczNi00LjUxMyAxLjE1Mi0xLjI3OCAyLjU3NC0yLjAyIDQuMjY3LTIuMjEtMS4zNDYtMS4wMS0yLjAyLTIuMzQ1LTIuMDItMy45OTYgMC0xLjM4OS40OTEtMi41ODIgMS40ODItMy41NzMuOTgyLS45OTEgMi4xNzYtMS40ODIgMy41ODEtMS40ODIgMS4zODkgMCAyLjU4Mi40OTEgMy41NzMgMS40ODIuOTkxLjk5MSAxLjQ5IDIuMTg0IDEuNDkgMy41NzMgMCAxLjY1MS0uNjY5IDIuOTg5LTIuMDIgMy45OTYgMS42OTMuMTk1IDMuMTE2LjkzMSA0LjI2NyAyLjIxIDEuMTYgMS4yNyAxLjczNiAyLjc3NyAxLjczNiA0LjUxMyAwIDEuMzg5LS4zNzMgMi42MzMtMS4xMjYgMy43MzQtLjc1MyAxLjEwMS0xLjcxIDEuOTIyLTIuODYyIDIuNDY0IDIuNzI2IDEuMDQxIDQuOTYxIDIuNzc3IDYuNjk3IDUuMjA3IDEuNzM2IDIuNDM4IDIuNTk5IDUuMTgyIDIuNTk5IDguMjM4IDAgMS43ODYtLjMyMiAzLjUxNC0uOTY1IDUuMTgyaC0xMy4zOTQiIGZpbGw9IiMxZjFhMTciIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC45NzMyNCAwIDEuMjQzKSIvPjwvc3ZnPg==");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 80% center;
    /* Adjust the first percentage to move it horizontally */
    font-size: small !important;
}

.turnplay .White {
    font-size: small !important;
    background-color: #1a1b18;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PHBhdGggZD0iTTIyLjUgOWMtMi4yMSAwLTQgMS43OS00IDQgMCAuODkuMjkgMS43MS43OCAyLjM4QzE3LjMzIDE2LjUgMTYgMTguNTkgMTYgMjFjMCAyLjAzLjk0IDMuODQgMi40MSA1LjAzLTMgMS4wNi03LjQxIDUuNTUtNy40MSAxMy40N2gyM2MwLTcuOTItNC40MS0xMi40MS03LjQxLTEzLjQ3IDEuNDctMS4xOSAyLjQxLTMgMi40MS01LjAzIDAtMi40MS0xLjMzLTQuNS0zLjI4LTUuNjIuNDktLjY3Ljc4LTEuNDkuNzgtMi4zOCAwLTIuMjEtMS43OS00LTQtNHoiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% center;
    /* Adjust the first percentage to move it horizontally */
    z-index: 3000 !important;

}


.turnplay {
    margin-left: 0vw !important;
    margin-top: 70%;
    position: fixed !important;
    font-size: xx-large !important;
    height: 40vh !important;
}

.col .turnis {
    font-size: xx-large !important;

    font-size: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;

}

.turnis {
    font-size: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 25vh;
}


.ratings .col {
    margin-bottom: 2px !important;
}


.hints {
    margin-left: 20vw !important;

    height: 50vh;
}




.hints .cg-wrap {
    $hintsboard: 40vw;
    margin-top: 0vw !important;
    margin-left: -4vw !important;
    width: $hintsboard !important;
    height: $hintsboard !important;
}

.turnis {
    margin-left: 0vw !important;
}


.col-landing {
    width: 100%;
    margin: 0 0%;
    margin-left: 3vw !important;
}

.img-landing {
    width: 90% !important;
    margin: 0 auto;

}

.column1 {
    width: 1% !important;
    margin: 2%;
    padding: 0;
    margin-top: -20px !important;
}

.column2 {
    margin-top: 0% !important;
    width: 90%;
    margin: 0;
    padding: 0;

}

.activity {
    width: 89% !important;
    margin: 10% 7%;
}

.calendar-wrapper {
    width: 140vw !important;
    position: relative !important;

    /* src/PrintPuzzle.css */
    .print-container {
        width: 210mm;
        height: 297mm;
        padding: 20mm;
        box-sizing: border-box;
        font-family: 'Arial', sans-serif;
        color: black;
        background-color: white;
    }

    h1 {
        font-size: 24px;
        text-align: center;
        margin-bottom: 20px;
    }

    .board {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .details {
        font-size: 14px;
        text-align: center;
    }

    padding-bottom: 56.25% !important;
    /* 16:9 aspect ratio */
    height: 600vh !important;
    overflow: hidden !important;
}

.calendar {
    padding-right: 0% !important;
    position: absolute !important;
    top: 0 !important;
    left: 1% !important;
    width: 90vw !important;
    height: 88vh !important;
    border: 0 !important;
}

div#calendarContainer1.view-container-border div.bubble {
    width: 10% !important;
}

.col1 {
    width: 70vw;
    margin-left: 14vw !important;
}

.col2 {

    margin-left: 14vw !important;

}

.modal {
    z-index: 10000 !important;
}


.colstatics {
    width: 20% !important;
}
}