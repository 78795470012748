/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1200px) {

  /* CSS */
  .logo {
    width: 30% !important;

  }

  .menu-left {
    width: 16% !important;
    margin-top: 0%;
    padding-top: 0% !important;
  }

  .logo-text {
    color: chocolate !important;
    font-weight: 700 !important;
  }

  .navbar-collapse a {
    margin: 2%;

  }

  .column2 {
    margin-left: 16%;
    margin-top: -52%;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1024px) and (max-width: 1280px) {

  /* CSS */
  .column1 {
    width: 10% !important;
    margin: 2%;
  }


  body {
    text-align: center;
    background-image:
      linear-gradient(rgba(72, 8, 8, 0.5), rgba(68, 194, 102, 0.5)),
      url("../../public/a3.webp ") !important;
    background-size: 100% auto;
    /* Adapt width to 100% of the element, and set the height automatically */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-attachment: fixed;
    /* Fix the background image to the viewport */
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {

  .col-landing {
    width: 44% !important;
  }

  body {
    text-align: center;
    background-image:
      linear-gradient(rgba(72, 8, 8, 0.5), rgba(68, 194, 102, 0.5)),
      url("../../public/a3.webp ") !important;
    background-size: 100% auto;
    /* Adapt width to 100% of the element, and set the height automatically */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-attachment: fixed;
    /* Fix the background image to the viewport */
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  /* CSS */

}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {

  /* CSS */

}

th {
  background-color: #94790f !important;
  color: white !important;
}

.row-color-0 {
  background-color: #f8f9fa !important;
}

.row-color-1 {
  background-color: #326aa2 !important;
}

.row-color-2 {
  background-color: #5f8f53 !important;
}

.contact {
  background-color: #0f5f94 !important;
  color: white !important;
}

.privacy-policy {
  background-color: #1a1b18 !important;
  color: white !important;
  margin: 2% auto auto 10%;
  width: 75%;
  border: solid;
  border-color: #d2691e;
  padding: 2%;
}

.info {
  width: 100% !important;
}