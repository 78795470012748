@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : landscape) {

    /* Styles for iPad Pro in landscape orientation */
    body {
        background-color: rgb(29, 120, 62) !important;
    }

    .cg-wrap {
        margin-left: 5vw !important;
    }

    .column1 {
        width: 16% !important;
    }


}