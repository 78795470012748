@media (min-height: 481px) and (max-height: 1000px)
/* Adjust the max-height as needed */
and (orientation: portrait) and (min-aspect-ratio: 9/21) {
    body {
        background-color: #1a1b18 !important;
    }

    .controlbottons {

        margin-top: 0vw !important;
        margin-left: auto !important;
        position: static !important;
        /* Note: Be cautious with 'position: fixed' as it was initially set */
    }

    .control-btn {
        width: 22vw !important;
        /* Adjust width as necessary, 'auto' or a specific value */
        height: auto !important;
        /* Adjust height as necessary, 'auto' or a specific value */
        padding: 1em !important;
        /* Adjust padding for visual appeal */
        writing-mode: horizontal-tb !important;
        /* Ensures standard horizontal text */
        text-align: center !important;

        justify-content: center !important;
        /* Center items horizontally, effective if 'display: flex' */
        align-items: center !important;
        /* Center items vertically, effective if 'display: flex' */
        transform: none !important;
        /* Removes any rotation */
        line-height: normal !important;
        display: inline-block !important;
        /* Could be 'block' or 'flex' depending on your needs */
    }

    .categoriespuzzles {
        width: auto !important;
        /* Sets width to default, adjusting based on content or container */
        height: auto !important;
        /* Sets height to default, adjusting based on content */
        margin-top: 0 !important;
        /* Resets the top margin to remove large spacing */
        margin-left: 0 !important;
        /* Resets the left margin to align with the natural document flow */
        padding: 1em !important;
        /* Provides a standard padding around the content; adjust as needed */
        writing-mode: horizontal-tb !important;
        /* Sets writing mode to standard horizontal */
        text-align: center !important;
        /* Keeps text alignment centered */
        display: block !important;
        /* Sets display to block for a "normal" layout, can use 'inline-block' if preferred */
        justify-content: flex-start !important;
        /* Resets to default alignment if kept as flex; might not be needed if not using flex display */
        align-items: stretch !important;
        /* Resets to default stretch if kept as flex; might not be needed if not using flex display */
        line-height: normal !important;
        /* Resets line height to default */
        position: static !important;
        /* Neutralizes fixed positioning, allowing it to flow in the document */
    }

    .custom-large-button {
        padding-left: 8px !important;
        /* Sets a standard padding-left, consider em units for responsiveness */
        margin-left: 0 !important;
        /* Resets the left margin to align with the natural document flow */
    }

    .infoPuzzle {

        margin-left: 6% !important;
        width: 82%;

    }

    .cardhood {
        background: none !important;
        max-width: 100% !important;
        margin-left: 0 !important;
    }

    .cardhood .btn {
        font-size: small !important;

    }

    .hints .cg-wrap {
        z-index: 1000 !important;
    }

    .alert {
        z-index: 5000 !important;
    }

    .cg-wrap {
        z-index: 0 !important;
        $hintsboard: 90vw;
        margin-top: 32vh !important;
        margin-left: 4vw !important;
        width: $hintsboard !important;
        height: $hintsboard !important;
    }




    .turnis {

        position: fixed !important;
        font-size: 16px !important;
        /* Use a fixed pixel value or em for scalability */
        margin-top: -60% !important;
        /* Remove additional spacing */
        margin-bottom: 0 !important;
        /* Remove additional spacing */
        height: auto !important;
        /* Allow the height to adjust based on content */
    }

    .turnplay .Black,
    .turnplay .White {
        margin-left: 10% !important;
        padding: 8px !important;

        font-size: medium !important;
        color: white !important;


        z-index: 1 !important;
        /* Standardize stacking order */
        display: inline-block !important;
        /* Allow buttons to sit in line with text or other inline elements */
        text-align: center !important;
        /* Center the text inside the button */
        width: 100% !important;
        /* Allow the width to adjust based on content, or set a specific width */
        height: 30% !important;
        /* Allow the height to adjust based on content, or set a specific height */
        position: fixed !important;
        margin-left: -60% !important;
        height: 10% !important;
        position: fixed !important;
        margin-top: 50% !important;
        z-index: 0 !important;
    }



    .infohood {
        margin-top: 3vw !important;
    }

    .bota {
        margin-top: 50% !important;

        width: 30% !important;
        z-index: 1 !important;
        position: fixed !important;
    }


}

@media screen and (min-width: 450px) and (max-width: 1024px) and (orientation: portrait) and (min-aspect-ratio: 9/21) {

    .bota {
        margin-top: 45% !important;

    }



}