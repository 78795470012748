@media (min-width: 1281px) {

    /* CSS */




    // // Bootstrap Breakpoints for max-width
    $breakpoints: (
        xs: 575.98px,
        // Upper limit for xs
        sm: 767.98px,
        // Upper limit for sm
        lg: 991.98px,
        // Upper limit for lg
        xl: 1199.98px,
        // Upper limit for xl
        xxl: 3000.98px // Upper limit for xxl
    );
//Pc xxl 1399.98px 
$withboardxxl: 47vw;
$infor-height: 19vw;
$logo-width: 5vw;
$cardwidth: 20vw;
$image-category: 3vw;
$card-img: 3;
$piecat: 17vw;
$categories-width: 27rem;
$menu-max-height: 36vw;
$cardhood-width: 27vw;

$cardhood-width-xxl-landscape: 35vw;
$cardhood-margin-left-xxl-landscape: 17vw;
$cg-wrap-margin-left-xxl-landscape: 1vw;
$editboardxxl: 44vw;
//Pc  1399.98px xxl 

//360x640 Galaxy S5  xs portrait

$cardhood-max-width-xs: 100%;
$navbarhood-margintop-sm-portrait: -4.2rem;
$cg-wrap-margin-top-sm-portrait: 18vw;
$logo-margin-left-sm-portrait: 18em;
$logo-width-sm-portrait: 8vw;
$logo-text-margin-left-sm-portrait: -55.5vw;
$infor-height-xs: 25vh;
$ratings-cards-margin-top: -330vw;
$ratings-cards-margin-bottom: 50vh;
$cardbottons-margin-top-xs: 100vw;
//360x640 Galaxy S5

$withboardxs: 97vw; // 575.98px
$withboardxl: 80vw; //   1199.98px
$withboardlg: 70vw; // 991.98px
$withboardsm: 60vw; //   767.98px

$withboard: 20vw; // 575.98px
$withboardxxs: 85vw; // 575.98px
//landscape board
$withboardxxllandscape: 50vw; //1399.98px
$withboardxllandscape: 65vw; //  1199.98px
$withboardlglandscape: 40vw; //  991.98px
$withboardsmlandscape: 45vw; //  767.98px
$withboardxslandscape: 90vw; //  575.98px
$withboardlandscape: 50vw; //- 575.98px
$withboardxxslandscape: 60vw; // 575.98px

//Colors bacgrounds
$body-bg: #1a1b18;
$category-item1-bg: $body-bg;
$card_bg: $body-bg;
$card-title-bg: #5a5a5ab5;

$scrollbar-ripe-malinka: #457737;
$scrollbar-ripe-malinka: linear-gradient(120deg, #030003 0%, #330a10 100%);

//borders
$card-title-border-bg: #f1bf08;

$border: none;
//Text color
$body-text-color: whitesmoke;
$Black-play-color: #000000;
$h2: #e76434;
$h3: #000000;
//measures
$puzzlecategoriesxs: vw;

body {

    background-color: $body-bg;

}

.logo {
    margin-left: 5% !important;
}

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}



.infor {

    max-height: $infor-height;
    overflow-y: auto;
    /* Enables vertical scrolling if content exceeds 100px */
}

.spare-pieces {
    display: inline-flex;
    max-width: 47vw;

}

.boardeditor .cg-wrap {

    width: $editboardxxl !important;
    height: $editboardxxl !important;
}

.piece {


    background-repeat: no-repeat;
    background-size: contain;
    /* or cover */
}

//finish Landscape
.scrollbar-ripe-malinka::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
    background-color: $scrollbar-ripe-malinka;

    border-radius: 10px;
}

.scrollbar-ripe-malinka::-webkit-scrollbar {
    width: 12px;
    background-color: $body-bg;
    border-color: green;
}

.scrollbar-ripe-malinka::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
    background-image: $scrollbar-ripe-malinka;

}

.example-1 {
    position: relative;
    overflow-y: scroll;
    height: $infor-height;

    width: $categories-width;
    border: $border;
}

.ratings {
    font-size: 0.9vw !important;
    text-align: center;
}




.category-item1 {

    background-color: $category-item1-bg;
}

.medal {
    width: 4vw;
}

.puzzle-rating {
    width: 3vw;

}

.puzzle-categories {
    text-align: center;
}

.logo {
    // margin-left: 2em;
    // margin-bottom: 1em;
    width: $logo-width;
    height: 100%;
}

.logo-text {
    font-weight: 500;
    font-size: xx-large;
    color: #e76434;
}

.image-category {

    width: $image-category;
    height: 100%;
}


.piecat {

    width: $piecat;
    height: 100%;
    padding-left: 2em;

}





.title-cat-img {
    text-align: center;
}

.text-center {
    text-align: center;
}

h2 {
    color: $h2;
    font-size: 1.2rem !important;
}



h3 {
    color: $h3
}


.Black {

    color: $Black-play-color;
}

.White {
    // background-color: $body-bg;
    color: $body-text-color;
}

.customCanvasBackgroundColor {
    background-color: $body-bg;
}



.cg-wrap {
    border-color: #e76434 !important;
    border: solid 4px;
    width: $withboard;
    height: $withboard;
}

.menu-after-logo {
    max-height: $menu-max-height;
    overflow-y: auto;


}



//scrool menu
.menu-after-logo::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(190, 26, 26, 0.1);
    background-color: $scrollbar-ripe-malinka;

    border-radius: 10px;
}

.menu-after-logo::-webkit-scrollbar {
    width: 12px;
    background-color: $body-bg;
    border-color: green;
}

.menu-after-logo::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-image: -webkit-linear-gradient(330deg, #073d17 0%, #d2e108 100%);
    background-image: $scrollbar-ripe-malinka;

}





.navbar {
    color: #d2e108;

}



@media screen and (max-width: map-get($breakpoints, 'xxl')) {


    .cg-wrap {

        margin-left: $cg-wrap-margin-left-xxl-landscape !important;
        width: $withboardxxl !important;
        height: $withboardxxl !important;
    }
}

@media screen and (max-width: map-get($breakpoints, 'xl')) {

    .cg-wrap {

        width: $withboardxl !important;
        height: $withboardxl !important;
        left: -2vw !important;
    }
}

@media screen and (max-width: map-get($breakpoints, 'lg')) {
    .cg-wrap {

        width: $withboardlg !important;
        height: $withboardlg !important;
    }
}









/* Accordion and Card Styles */





/* Styles for the content inside the accordion */
.category-item1 {
    width: 100%;
    /* Ensure the inner cards take full width */
}






.afterpgneditor {
    max-width: 20vw !important;
    max-height: 20vw !important;
}




.hints {
    display: block !important;
}

.login-google-alert {
    width: 22vw !important;
    z-index: 1;
}



.modal-content {
    display: block !important;
    margin: 0 auto !important;
    max-width: 100%;
    background-color: $body-bg;
    color: $body-text-color;
}

.modal-title {
    margin: 0 auto !important;
}





ul li {
    text-align: left;
}

button {
    font-size: 12px;
    /* Adjust as needed */
}

.buttonscat {
    margin: 0.2em;
    width: 100% !important;
    font-size: large !important;
}



.column1 {


    color: white !important;
    width: 16%;
}

.column1 .btn {
    width: 100%;
    padding: 1vw;
    margin: 0.5vw;
}

.iconess {
    width: 3vw;
    height: 3vw;
}

.col {
    background-color: #1a1b18;

    border: 2px solid #e76434;
    margin: 3%;
    padding: 0.9rem;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    /* Hover effect to slightly lighten the background */
    &:hover {
        background-color: #1a1b18;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Increase shadow on hover */

    }
}

.control-btn {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

    width: 30%;
    padding: 1vw;
    margin: 0.4vw;
}

.line2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    width: 100%;
    color: whitesmoke;
}

.turnis {

    font-size: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
}

.hint {
    display: block !important;
}


.alert {

    border: 3px solid #757e72;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    position: fixed;
}

.alert-success {
    width: 30vw !important;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 3px solid #5ca145;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}

.col .alert {
    border: 3px solid #dff306 !important;
}

.col .alert-success {
    border: 3px solid #5ca145 !important;
}



.h2 {
    color: #e76434 !important;
}

.alert-error {
    width: 30vw !important;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 2px solid #e76434;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}


/* UserCategories.css */

.scrollable-list {
    max-height: 140px;
    overflow-y: auto;
    border: 1px solid #ccc;
    /* Optional: adds a border around the list */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds a subtle shadow */
    list-style-type: none;
    /* Removes default list bullet points */
    padding: 0;
    /* Resets default padding */
    margin: 0;
    /* Resets default margin */
}

.scrollable-list li {
    padding: 10px;
    /* Adds some padding inside each list item for better spacing */
    color: #333;
    /* Dark text color for better readability */
}

.scrollable-list li:nth-child(odd) {
    background-color: #fff;
    /* White background for odd items */
}

.scrollable-list li:nth-child(even) {
    background-color: #f2f2f2;
    /* Light gray background for even items */
}

@keyframes hurrahAnimation {

    0%,
    100% {
        transform: scale(1);
        /* Original size */
    }

    50% {
        transform: scale(1.2);
        /* Scale up to 120% */
    }
}

.happy {
    display: block;
    margin: 0 auto;
    width: 14%;
    text-align: center;
    color: $body-text-color;
    /* Make sure this variable is defined or replace it with a specific color */
    animation: hurrahAnimation 0.5s ease-in-out infinite;
}


.alert-heading {
    background-color: #1a1b18;
    border: 2px solid #e76434;
    margin: 3%;
    padding: 0.9rem;
    text-align: center;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.alert-success .alert-heading {
    background-color: #1a1b18;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 3px solid #5ca145;
}

.hints {
    $withboardpchint: 20vw;
    max-height: $withboardpchint !important;
}

.hints .cg-wrap {
    margin-left: 7vw !important;
    margin-top: 1% !important;
    $withboardpchint: 12vw;
    width: $withboardpchint !important;
    height: $withboardpchint !important;

}

.alert {
    max-height: 90vh !important;
    width: 30vw !important;
    color: #ccc !important;
    text-align: center !important;
}

.custom-large-button {
    padding-left: 1.5vw !important;
    margin-left: 1vw !important;

}

}