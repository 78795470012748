/* Target iPads in portrait orientation, including retina displays */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation : portrait) {
    $withboardsmlandscape: 75vw;

    body {
        background-color: #1a1b18 !important;
    }



    .cg-wrap {
        z-index: 0 !important;
        margin-left: 10% !important;
        margin-top: 48% !important;
        width: $withboardsmlandscape !important;
        height: $withboardsmlandscape !important;
    }

    .turnplay {
        margin-left: -50vw;
        margin-top: 85% !important;
        position: fixed !important;
        font-size: xx-large !important;
        height: 20vh !important;
        width: 100% !important;


    }

    .turnis {

        font-size: xx-large !important;
        margin-top: 7vw;
        margin-bottom: 1vw;
        height: 14vh;
        width: 100%;
        padding: 4vw;
        max-height: fit-content !important;
        background-size: 10% !important;
        z-index: 1000 !important;

    }

    .turnplay .White {
        padding: 8px !important;
        font-size: xx-large !important;

    }

    .control-btn {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        width: 30% !important;
        padding: 1vw;

        margin: 0.4vw;
    }

    .categoriespuzzles {
        margin-top: 2% !important;
    }

    .custom-large-button {
        height: 7vh !important;


    }

    .infohood {
        margin-top: 5vw !important;
    }



    .hints .cg-wrap {
        $hintsboard: 50vw;
        margin-top: 0vw !important;
        margin-left: 0vw !important;
        width: $hintsboard !important;
        height: $hintsboard !important;
    }




}

@media only screen and (min-device-width : 1024px) and (max-device-width : 1500px) and (orientation : landscape) {
    .column2 {
        margin-top: -67% !important;
    }




}