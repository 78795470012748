body {
    max-width: 1800px !important;
    margin: 0 auto;
}

.ai {
    background-color: #3f6243;
    margin-top: 0% !important;
    padding: 3% 5% !important;
}

$body-text-color: #333;

.col {
    background-color: #1a1b18;
    border: 2px solid #e76434;
    margin: 5%;
    margin-bottom: 9%;
    padding: 0.2rem;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    /* Hover effect to slightly lighten the background */
    &:hover {
        background-color: #1a1b18;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Increase shadow on hover */

    }
}

.control-btn {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);


    width: 30%;
    padding: 1vw;
    margin: 0.4vw;
}

.line2 {
    margin-top: 1vw;
    margin-bottom: 1vw;
    width: 100%;
    color: whitesmoke;
}

.turnis {

    font-size: 1.5vw;
    margin-top: 1vw;
    margin-bottom: 1vw;
    height: 20vh;
}



.btn-close .alert {
    max-width: 25vw;
    height: 80vh;
    background-color: #e76434;
    color: white;
    border: 3px solid #757e72;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 10;
    position: fixed;
}

.alert-success,
.alert-warning {
    width: 70vw;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 3px solid #5ca145;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}

.col .alert {
    border: 3px solid #dff306;
}

.col .alert-success {
    border: 3px solid #5ca145;
}

.h2 {
    color: #e76434;
}

.alert-error {
    width: 70vw;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 2px solid #e76434;
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

}


/* UserCategories.css */

.scrollable-list {
    margin-top: 20vh;
    margin-left: -20px;
    max-height: 140px;
    overflow-y: auto;
    border: 1px solid #ccc;
    /* Optional: adds a border around the list */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Optional: adds a subtle shadow */
    list-style-type: none;
    /* Removes default list bullet points */
    padding: 0;
    /* Resets default padding */

    /* Resets default margin */
}

.scrollable-list li {
    padding: 10px;
    /* Adds some padding inside each list item for better spacing */
    color: #333;
    /* Dark text color for better readability */
}

.scrollable-list li:nth-child(odd) {
    background-color: #fff;
    /* White background for odd items */
}

.scrollable-list li:nth-child(even) {
    background-color: #f2f2f2;
    /* Light gray background for even items */
}

@keyframes hurrahAnimation {

    0%,
    100% {
        transform: scale(1);
        /* Original size */
    }

    50% {
        transform: scale(1.2);
        /* Scale up to 120% */
    }
}

.happy {
    display: block;
    margin: 0 auto;
    width: 5vw;
    text-align: center;
    color: $body-text-color;
    /* Make sure this variable is defined or replace it with a specific color */
    animation: hurrahAnimation 0.5s ease-in-out infinite;
}


.alert-heading {
    background-color: #1a1b18;
    border: 2px solid #e76434;
    margin: 3%;
    font-size: 1.9vw;
    padding: 0.2rem;
    text-align: center;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.alert-success .alert-heading {
    background-color: #1a1b18;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border: 3px solid #5ca145;
}

.scrollable-list {
    max-height: 80px;
    max-width: 60%;
    margin-left: 50%;
    margin-top: 0%;
}





.infohood {
    text-align: center;
}

.alertcategoriespuzzle {
    max-width: 40rem;
    background-color: #1a1b18;
    color: whitesmoke;
    border-color: #e76434;

    position: fixed;


}

.play-active {
    position: fixed;
    color: whitesmoke;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* This prevents scrolling */
}





.turnplay .Black {


    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNzcuMTciIGhlaWdodD0iMTc3LjE3IiBzaGFwZS1yZW5kZXJpbmc9Imdlb21ldHJpY1ByZWNpc2lvbiIgaW1hZ2UtcmVuZGVyaW5nPSJvcHRpbWl6ZVF1YWxpdHkiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiB2aWV3Qm94PSIwIDAgNTAgNTAiPjxwYXRoIGQ9Im0yNSA0Ni40NWgtMTMuMzk0Yy0uNjYtMS42NTEtLjk5MS0zLjM3OC0uOTkxLTUuMTgyIDAtMy4wNTYuODY0LTUuOCAyLjU5OS04LjIzOCAxLjczNi0yLjQzIDMuOTcxLTQuMTY2IDYuNjk3LTUuMjA3LTEuMTY4LS41NDItMi4xMjUtMS4zNjMtMi44NjItMi40NjQtLjczNy0xLjEwMS0xLjEwMS0yLjM0NS0xLjEwMS0zLjczNCAwLTEuNzM2LjU3Ni0zLjI0MyAxLjczNi00LjUxMyAxLjE1Mi0xLjI3OCAyLjU3NC0yLjAyIDQuMjY3LTIuMjEtMS4zNDYtMS4wMS0yLjAyLTIuMzQ1LTIuMDItMy45OTYgMC0xLjM4OS40OTEtMi41ODIgMS40ODItMy41NzMuOTgyLS45OTEgMi4xNzYtMS40ODIgMy41ODEtMS40ODIgMS4zODkgMCAyLjU4Mi40OTEgMy41NzMgMS40ODIuOTkxLjk5MSAxLjQ5IDIuMTg0IDEuNDkgMy41NzMgMCAxLjY1MS0uNjY5IDIuOTg5LTIuMDIgMy45OTYgMS42OTMuMTk1IDMuMTE2LjkzMSA0LjI2NyAyLjIxIDEuMTYgMS4yNyAxLjczNiAyLjc3NyAxLjczNiA0LjUxMyAwIDEuMzg5LS4zNzMgMi42MzMtMS4xMjYgMy43MzQtLjc1MyAxLjEwMS0xLjcxIDEuOTIyLTIuODYyIDIuNDY0IDIuNzI2IDEuMDQxIDQuOTYxIDIuNzc3IDYuNjk3IDUuMjA3IDEuNzM2IDIuNDM4IDIuNTk5IDUuMTgyIDIuNTk5IDguMjM4IDAgMS43ODYtLjMyMiAzLjUxNC0uOTY1IDUuMTgyaC0xMy4zOTQiIGZpbGw9IiMxZjFhMTciIHRyYW5zZm9ybT0ibWF0cml4KDEgMCAwIC45NzMyNCAwIDEuMjQzKSIvPjwvc3ZnPg==");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: 50% center;
    /* Adjust the first percentage to move it horizontally */
    z-index: 3000;

}

.turnplay .White {

    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0NSIgaGVpZ2h0PSI0NSI+PHBhdGggZD0iTTIyLjUgOWMtMi4yMSAwLTQgMS43OS00IDQgMCAuODkuMjkgMS43MS43OCAyLjM4QzE3LjMzIDE2LjUgMTYgMTguNTkgMTYgMjFjMCAyLjAzLjk0IDMuODQgMi40MSA1LjAzLTMgMS4wNi03LjQxIDUuNTUtNy40MSAxMy40N2gyM2MwLTcuOTItNC40MS0xMi40MS03LjQxLTEzLjQ3IDEuNDctMS4xOSAyLjQxLTMgMi40MS01LjAzIDAtMi40MS0xLjMzLTQuNS0zLjI4LTUuNjIuNDktLjY3Ljc4LTEuNDkuNzgtMi4zOCAwLTIuMjEtMS43OS00LTQtNHoiIGZpbGw9IiNmZmYiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==");
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: 50% center;
    /* Adjust the first percentage to move it horizontally */
    z-index: 3000;

}

.hints .cg-wrap {
    $hintsboard: 20vw;
    margin-left: 20%;
    margin-top: 2vw;
    margin-bottom: 0vw;
    width: $hintsboard ;
    height: $hintsboard ;
}

.firstmoves {
    max-height: 100vh !important;
}



.btn-close {
    max-width: 25vw;
    height: 1vw;
    background-color: #e76434;
    color: white;
    border: 3px solid #db7f28;
    top: 10%;
    transform: translate(-50%, -50%);
    margin-top: 3vw !important;
    z-index: 10;

}

.navbar-toggler {

    background-color: #e76434;
}

.column1 {
    height: 100vh !important;
}

.firstmoves {

    margin-top: 20vh !important;
}


.column1>.home {
    position: fixed !important;
    height: 100% !important;

}

.home h2 {
    text-align: center !important;
    font-size: 45px !important;
}

.home {

    padding-top: 2%;
    color: #e0e1d9;
}

.homeai {
    padding: 2% 10%;
    color: #e0e1d9;
}

.menu-left {
    z-index: 10000 !important;
    position: fixed;
}

.iconhome1,
.iconhome2,
.iconhome2,
.iconhome3,
.iconhome4,
.iconhome5,
.iconhome6,
.iconhome7,
.iconhome8 {
    padding: 1.3%;
    list-style-type: none;
    /* Remove default list-style */
    padding-left: 40px;
    /* Add padding to accommodate the background image */
    background-image: url('../../public/iconhome1.png');
    /* Path to your icon */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-size: 30px 30px;
    /* Size of the icon */
    background-position: left center;
    /* Position the icon to the left and vertically center */
}

.homeimg {
    width: 20vw;

    margin: 0 7%;

}

.imgcolhome {
    margin-top: 0% !important;
    margin-bottom: 8% !important;
    width: 98%;
    margin-left: 0% !important;



}

.App-header {
    width: 95%;

}

h3 {
    color: #5ca145 !important;
}


body {
    text-align: center;
    background-image:
        linear-gradient(rgba(72, 8, 8, 0.5), rgba(68, 194, 102, 0.5)),
        url("../../public/a2.webp ") !important;
    background-size: 100% auto;
    /* Adapt width to 100% of the element, and set the height automatically */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    background-attachment: fixed;
    /* Fix the background image to the viewport */
}


// .cg-wrap piece.king.black {
//     background-image: url('../../public/a2.png') !important;
// }

.bota {
    max-width: 250px;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.App {
    text-align: center;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.checkout {
    padding-top: 10px;
}

.checkout-title {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-transform: uppercase;
    margin: 0;
    color: rgb(232, 129, 11);
}

.checkout-price {
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 95px;
    margin: 0;
    color: rgb(242, 191, 50);
}

.checkout-description {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    max-width: 200px;
    margin: 0 auto;
    margin-top: 10px;
    color: rgba(194, 191, 191, 0.7);
}

.checkout-product-image {
    width: 14vw;
}

.checkout-button {
    background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
    border: 0.5px solid rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
        0px 20px 40px rgba(23, 0, 102, 0.2);
    border-radius: 20px;

    display: flex;

    padding: 4px;
    width: 14vw;
    cursor: pointer;
    margin: 0 auto;
    margin-top: 20px;

    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-button:focus {
    outline: none;
}

.checkout-button:hover {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1),
        0px 30px 60px rgba(23, 0, 102, 0.3);
    transform: translateY(-2px);
}

.checkout-button .grey-circle {
    background: rgba(68, 66, 178, 0.1);
    box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-button .purple-circle {
    background: linear-gradient(200.44deg, #4316db 13.57%, #9076e7 98.38%);
    box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-button .icon {
    width: 20px;
    height: 20px;
    margin: 0px;
}

.checkout-button .text-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 4px;
    margin: auto;
    text-align: left;
    margin-left: 16px;
}

.checkout-button .text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4vw;
    line-height: 130%;
    margin: 0;
    color: rgb(5, 5, 5);
    margin: 0;
}

.susccheck {
    color: #e76434;
}

.promotionbox {
    position: fixed;
    z-index: 1000;
    width: 40vw;

    background-color: #44483b;
    border: 2px solid #e76434;
    margin: 5%;
    margin-bottom: 9%;
    padding: 3rem;
    border-radius: 0.4rem;
    /* Add border-radius for rounded corners */
    box-shadow: 22px 13px 10px rgba(0, 0, 0, 0.9);
    /* Add box shadow for depth */
    /* Add box shadow for depth with increased opacity */

    /* Add transition for smoother hover effect */
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    /* Hover effect to slightly lighten the background */
    &:hover {
        background-color: #1a1b18;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* Increase shadow on hover */

    }


}

.promotionbox img {
    width: 7vw !important;

}

i {
    color: #e73434;
}

.stats {
    color: #e76434;
}

.login a {
    color: #e76434;
}

.login a:hover {
    color: #34e755;
}

.chess-club {

    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h1 {
        text-align: center;
        color: #333;
    }

    h2 {
        text-align: center;
        color: #555;
    }

    .details,
    .about,
    .schedule,
    .location,
    .contact {
        margin-bottom: 20px;
    }

    .schedule ul {
        list-style-type: none;
        padding: 0;

        li {
            background: #eee;
            color: #1a1b18 !important;
            margin: 5px 0;
            padding: 10px;
            border-radius: 4px;
        }
    }

    .register {
        text-align: center;
        margin-top: 20px;

        .register-button {
            background-color: #007bff;
            color: white;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            text-decoration: none;
            font-size: 16px;

            &:hover {
                background-color: #0056b3;
            }
        }
    }

    .image {
        text-align: center;
        margin-top: 20px;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }
    }

    @media (max-width: 600px) {
        padding: 10px;

        h1,
        h2 {
            font-size: 1.5em;
        }

        .register-button {
            width: 100%;
        }
    }
}

.imgshirley {
    width: 40vw;
    margin: 0 7%;
}

.activity {
    width: 15vw;
    margin: 0 7%;
}

.col-landing {
    width: 30%;
    margin: 0 7%;
}

.bg-light-landing {
    background-color: #abadb1 !important;
    color: #1a1b18 !important;

}

.bg-light-landing h3 {
    color: #1a1b18 !important;
}

.img-landing {
    width: 15vw;
    margin: 0 1%;
}


.calendar-wrapper {
    width: 130% !important;
    position: relative !important;
    padding-bottom: 56.25% !important;
    /* 16:9 aspect ratio */
    height: 600vh !important;
    overflow: hidden !important;
}

.calendar {
    position: fixed !important;
    top: 5% !important;
    left: 50cap !important;
    width: 50vw !important;
    height: 88vh !important;
    border: 0 !important;
}

/* src/PrintPuzzle.css */
.print-container {
    width: 210mm;
    height: 297mm;
    padding: 20mm;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
    color: black;
    background-color: white;
}

h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.board {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.details {
    font-size: 14px;
    text-align: center;
}

.subscription-alert {
    position: fixed;
    z-index: 10000 !important;
}

.success {
    color: #ccc !important;
}

/* CookiePolicy.css */
.cookie-policy {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
    margin: 20px auto;
    max-width: 800px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cookie-policy h1,
.cookie-policy h2,
.cookie-policy h3 {
    color: #333;
}

.cookie-policy p {
    margin-bottom: 15px;
    color: #555;
}

.cookie-policy ul {
    margin-bottom: 15px;
    padding-left: 20px;
}

.cookie-policy ul li {
    list-style-type: disc;
    margin-bottom: 10px;
}

.cookie-policy a {
    color: #007bff;
    text-decoration: none;
}

.cookie-policy a:hover {
    text-decoration: underline;
}

.cookie-policy button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cookie-policy button:hover {
    background-color: #45a049;
}

.window-dimensions {
    position: fixed;
    bottom: 1px;
    color: rgba(0, 0, 10, 10);
    /* Fully transparent text */
}

.colstatics {
    width: 25% !important;
    text-align: center;
}