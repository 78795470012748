#root {
    max-width: 1800px !important;
}

@media (max-aspect-ratio: 16/9) {
    .cg-wrap {
        $board: 70vw !important;
        width: $board ;
        height: $board ;
    }
}

/* When height is greater than twice the width (portrait extreme) */
@media (min-aspect-ratio: 1/2) {
    .cg-wrap {
        $board: 10vw !important;
        width: $board ;
        height: $board ;
    }
}

/* When height is greater than three times the width (portrait extreme) */
@media (min-aspect-ratio: 1/3) {


    .cg-wrap {
        $board: 87vw !important;
        width: $board ;
        height: $board;
    }

    /*iPad Pro12.9*/
    @media screen and (min-width: 768px) and (orientation: landscape) {
        .cg-wrap {
            margin-top: -5% !important;
            margin-left: 2% !important;
            $board: 50vw !important;
            width: $board ;
            height: $board ;
        }

        @media screen and (min-height: 500px) and (min-width: 768px) {
            .cg-wrap {
                margin-top: 5% !important;
                margin-left: 2% !important;
                $board: 50vw !important;
                width: $board ;
                height: $board ;
            }
        }
    }

    @media screen and (max-width: 768px) and (orientation: landscape) {
        .cg-wrap {
            margin-top: 2% !important;
            $board: 50vw !important;
            width: $board ;
            height: $board ;
        }
    }

    @media screen and (max-width: 768px) and (orientation: portrait) {


        .cg-wrap {
            margin-top: 70% !important;
            $board: 87vw !important;
            width: $board ;
            height: $board ;
        }



    }

    @media screen and (min-height: 1000px) and (min-width: 768px) and (orientation: portrait) {
        .cg-wrap {
            margin-top: 45% !important;
            margin-left: 0% !important;
            $board: 87vw !important;
            width: $board ;
            height: $board ;
        }

        .bota {
            width: 50%;
            margin-top: 80% !important;

        }

    }

    @media screen and (min-height: 1200px) and (orientation: portrait) {
        .cg-wrap {
            margin-top: 50% !important;
            margin-left: 10% !important;
            $board: 70vw !important;
            width: $board ;
            height: $board ;
        }

        .bota {
            width: 20%;
            margin-top: 12% !important;
            margin-left: -40%;

        }

    }
}


/* When height is greater than four times the width (portrait extreme) */
@media (max-aspect-ratio: 1/4) {
    .cg-wrap {
        $board: 24vw !important;
        width: $board;
        height: $board;
    }
}

/* When width is greater than twice the height (landscape extreme) */
@media (min-aspect-ratio: 2/1) {
    .cg-wrap {
        margin-left: 10% !important;
        margin-top: 5% !important;
        $board: 40vw !important;
        width: $board ;
        height: $board ;
    }


    @media screen and (min-width: 768px) and (max-height: 768px) and (orientation: landscape) {
        .cg-wrap {
            margin-top: 3% !important;
            margin-left: 5%;
            $board: 80vh !important;
            width: $board ;
            height: $board ;
        }
    }
}

/*samsung fold small screel landscape*/
/* When width is greater than three times the height (landscape extreme) */
@media (min-aspect-ratio: 3/1) {
    .cg-wrap {
        $board: 70vh !important;
        width: $board ;
        height: $board ;
    }
}

/* When width is greater than four times the height (landscape extreme) */
@media (min-aspect-ratio: 4/1) {
    .cg-wrap {
        $board: 75vh !important;
        width: $board ;
        height: $board;
    }
}

/* CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    padding: 15px;
    z-index: 1000;
}

.cookie-consent button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 14px;
}

.cookie-consent button:hover {
    background-color: #45a049;
}