/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
    651 400 landscape fold 
  */
/* Consulta de medios para pantallas desplegadas */
@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape)and (min-aspect-ratio: 4/3) {

    /* Landscape styles go here */

    body {
        background-color: #1a1b18 !important;
        font-size: x-small !important;
        padding: 1.3vw;

    }



    body img {

        width: 44% !important;
    }

    .column1 {
        z-index: 111;
    }

    .column3 {

        padding-left: 12% !important;

    }

    .column4 {
        margin-left: -10% !important;
        width: 33%;
        padding-left: 5% !important;
        padding-right: 0% !important;
        margin-right: 0% !important;
    }

    .cg-wrap {
        margin-top: 2% !important;
        margin-left: -6% !important;
        position: fixed !important;
        $boarwidth: 55vw;
        width: $boarwidth !important;
        height: $boarwidth !important;
        font-size: x-small !important;
    }

    /*IPHON 13 LANDSCAPE ALSO*/
    @media screen and (min-height: 200px)and (max-height: 380px) {
        .cg-wrap {
            margin-top: 2%;
            margin-left: 4% !important;
            position: fixed !important;
            $boarwidth: 70vh;
            width: $boarwidth !important;
            height: $boarwidth !important;
            font-size: x-small !important;
        }
    }

    .navbarhood .show {
        margin-top: 16rem;
        padding: 2% !important;
        margin-left: 2rem !important;
        position: fixed !important;
        z-index: 1000 !important;
        width: 20% !important;
        font-size: x-small !important;
        background-color: rgba(24, 82, 97, 0.9) !important;
        border-color: #e2270e !important;
        border: 1px solid #e2270e !important;
        /* Adjust the alpha value (0.5) for the desired transparency */
        border-radius: 10px;



        width: 10rem !important;
        border-radius: 10px;
    }



    .navbarhood img {
        width: 30% !important;
    }

    .navbarhood button {
        width: 100% !important;
        font-size: x-small;
        font-size: x-small !important;
    }


    .navbar-toggler {
        width: 25% !important;
        font-size: x-small !important;
    }

    .infoPuzzle {
        position: fixed !important;
        max-width: 140% !important;
        margin-left: 57% !important;
        float: right !important;
    }

    .ratings {

        font-size: x-small !important;
        margin-top: -11%;

    }

    .card {
        width: 100% !important;
        margin-bottom: -5% !important;
        background-color: rgb(29, 31, 32) !important;
        font-size: x-small !important;
        color: white !important;
        padding: 0% !important;
    }

    .app-container {
        width: 100% !important;
        font-size: x-small !important;
        overflow-y: hidden !important;
        overflow-x: hidden !important;
        scroll-behavior: unset !important;

    }

    h2,
    p,
    span,
    li,
    ul,
    ol,
    div,
    button,
    form,
    textarea,
    select,
    input,
    table,
    th,
    td,
    nav,
    footer,
    header,
    section,
    article,
    aside,
    main,
    figure,
    figcaption,
    blockquote,
    code,
    pre,
    abbr,
    address,
    cite,
    em,
    i,
    strong,
    small,
    sub,
    sup,
    mark,
    time,
    var {
        font-size: x-small !important;
    }

    .home h2 {
        text-align: center !important;
        font-size: small !important;
    }

    .col-landing {
        width: 44% !important;
        font-size: x-small !important;
    }

    .img-landing {
        width: 100% !important;

    }

    .activity {
        width: 80% !important;
        font-size: x-small !important;
    }



    button {
        font-size: 12px;
        /* Adjust as needed */
    }

    .buttonscat {
        width: 100% !important;
        font-size: xx-small !important;
    }

    .cg-wrap coords.ranks {
        top: 0px !important;
        left: -5% !important;
        color: red !important;
    }

    .cg-wrap coords.ranks {
        top: 0px !important;
        left: -5% !important;
        color: red !important;
    }

    .cg-wrap coords.files {

        top: 90% !important;
        left: 0% !important;
        color: red !important;
    }

    .cg-sq-coord {
        /* Replace with the actual class name */
        background-color: red !important;
    }

    .buttonscat {
        margin: 2%;
    }

    .navbar-toggler {
        background-color: rgb(109, 176, 201) !important;

        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);

        color: azure !important;
        width: 100% !important;
        font-size: x-small !important;
    }

    .bottonfold {

        width: 100% !important;
        font-size: x-small !important;
        margin: 0.5vw !important;
    }


    .logo {
        margin: 3% !important;

    }

    .logo-text {
        color: whitesmoke !important;
    }

    .navbar-collapse .colapse {
        background-color: #466118 !important;
        width: 10vw !important;
    }

    .h2 {
        color: #e76434 !important;
    }

    .hintall>div:nth-child(1)>div {
        $hintallwidth: 30vw;
        width: $hintallwidth !important;
        height: $hintallwidth !important;
    }

    .hintall {
        height: 100% !important;
    }

    .modal {
        height: 95% !important;
    }

    .hintbuttons {
        margin-top: -650px !important;
    }

    .alert {
        position: fixed;
        top: 20px;
        /* Adjust as needed to position the alert from the top */
        left: 50%;
        /* Center relative to the viewport */
        transform: translateX(-50%);
        /* Shift it back to the left by half its width */
        z-index: 1000;
        width: 50%;
        max-height: 85%;
    }

    .control-btn {
        width: 33% !important;

    }

    .scrollable-list {
        margin-top: -20vw !important;
        margin-left: 50% !important;
        max-height: 80px;
        max-width: 60% !important;

    }


}